<template>
  <div class="wrap">
    <div class="userinfo_bar">
      <div class="nav_title">{{ $t("newAdd.newNavTitle") }}</div>
      <VanImage width="70" height="70" :src="$img(userInfo.userimg)" round>
        <VanImage slot="error" width="70" height="70" :src="require('@/assets/Img/myImg/MyOne.png')" round />
      </VanImage>
      <div class="level_bar">
        <div class="nickname">
          {{
        userInfo.username ? userInfo.username : $t("newAdd.noLoginUserName")
      }}
        </div>
        <div class="hr"></div>
        <div class="level">{{ userInfo.rank_title }}</div>
      </div>
      <div class="record_bar" @click="$router.push('/giftList')">
        <i class="iconfont icon-lishijilu"></i>
      </div>
      <div class="tradeinfo_bar">
        <div class="id_bar">
          <div class="title">ID</div>
          <div class="value">{{ userInfo.id }}</div>
        </div>
        <div class="balance">
          <div class="title">{{ $t("Home.WithdrawSu.Balance") }}</div>
          <div class="value">
            {{ $money(Number(userInfo.money).toFixed(2) || "0.00", false) }}
          </div>
        </div>
      </div>
    </div>
    <div class="shop">
      <div class="shop_title">{{ $t('gift.gift_shop') }}</div>
      <div class="gifts">
        <div class="gifts_cont">
          <div class="lists" v-if="giftList.length">
            <div class="cont_list"
              :style="activeIndex == index ? { borderColor: theme == 'light' ? 'var(--theme)' : 'var(--sizeColor)' } : {}"
              v-for="(item, index) in giftList" @click="listClick(index)">
              <div class="list_img">
                <img :src="item && item.picture && $img(item.picture[0])" alt="">
              </div>
              <span class="list_title">{{ item.title }}</span>
              <div class="list_money">$ {{ item.price }}</div>
            </div>
          </div>
          <div class="lists" v-else>
            <div class="nodata">{{ $t('gift.overlay.no_data') }}</div>
          </div>
          <div class="gifts_btns">
            <div class="btn left_btn" @click="buyClick"
              :style="theme == 'light' ? { color: 'var(--btnsizeColor)' } : {  }">
              {{ $t('gift.buy') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="shop">
      <div class="shop_title">{{ $t('gift.my_gift') }}</div>
      <div class="gifts">
        <div class="gifts_cont">
          <div class="lists" v-if="mygift.length">
            <div class="cont_list"
              :style="myactiveIndex == index ? { borderColor: theme == 'light' ? 'var(--theme)' : 'var(--sizeColor)' } : {}"
              v-for="(item, index) in mygift" @click="mylistClick(index)">
              <div class="list_img">
                <img :src="item && item.product_picture && $img(item.product_picture[0])" alt="">
              </div>
              <span class="list_title">{{ item.product_name }}</span>
              <div class="list_money">x{{ item.amount - item.gift_amount }}</div>
            </div>
          </div>
          <div class="lists" v-else>
            <div class="nodata">{{ $t('gift.overlay.no_data') }}</div>
          </div>
          <div class="gifts_btns">
            <div class="btn left_btn" :style="theme == 'light' ? { color: 'var(--btnsizeColor)' } : { }"
              @click="sendClick">
              {{ $t('gift.away') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="game-btn" @click="handleBuys"
      :style="theme == 'light' ? { color: 'var(--btnsizeColor)', backgroundColor: 'var(--btnColor)' } : {  }">
      <div class="game-btn-text-bc">
        {{ $t("newAdd.OrderImmediately") }}
      </div>
    </div>
    <div class="make-synopsis">
      <div class="make-synopsis-title">
        <span>{{ $t("newAdd.OrderDescription") }}</span>
      </div>
      <div class="make-synopsis-content">
        <div>

          <template v-if="ver === 'myntra'">
            <p style="font-size: 16px">
              When you click auto-grab, the mall will automatically issue an
              order task. You need to complete the payment for the order. The
              order task needs to be completed within 60 minutes. After grabbing
              the order, please complete the task as soon as possible. <br />
              If you have any questions, please contact customer service.
            </p>
            <br />
          </template>

          <template v-else-if="ver === 'amigo_vi'">
            <p v-for="(item, index) in 7" :key="'p' + index">
              <strong><span style="color: var(--theme); font-size: 10px">({{ item }})</span></strong><span
                style="font-size: 10px">
                {{
        $t("viGrabDesces." + item, {
          rate: levels[userInfo.level]
            ? levels[userInfo.level].rate
            : 0,
        })
      }}
                <!-- {{ $t("newAdd.DescriptionRate") }} </span><span style="color: #e23d6d; font-size: 10px">{{
                levels[userInfo.level]
                ? levels[userInfo.level].rate
                : 0
                }}%</span><span style="font-size: 10px">
                {{ $t("newAdd.DescriptionPeriod") }} -->
              </span>
            </p>
            <p>
              <br />
            </p>
          </template>

          <template v-else>
            <!-- <p>
              <span style="color: #337fe5; font-size: 10px"><strong>(1)</strong></span><span style="font-size: 10px">
                {{ $t("newAdd.CouldMatch") }} </span><span style="color: #e23d6d; font-size: 10px">
                {{
                userInfo.level ?
                levels[userInfo.level] ? levels[userInfo.level].order_num :
                orderNumObj[userInfo.level]
                : userInfo.level == 0
                ? 1
                : 0
                }}
              </span><span style="font-size: 10px">
                {{ $t("newAdd.OrderPerDay") }}</span>
            </p> -->
            <p>
              <br />
            </p>
            <p>
              <strong><span style="color: var(--theme); font-size: 10px">(1)</span></strong><span
                style="font-size: 10px">
                {{ $t("newAdd.DescriptionRate") }} </span><span style="color: #e23d6d; font-size: 10px">{{
        levels[userInfo.level]
          ? levels[userInfo.level].rate
          : 0
      }}%</span><span style="font-size: 10px">
                {{ $t("newAdd.DescriptionPeriod") }}</span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="color: var(--theme); font-size: 10px"><strong>(2)</strong></span><span
                style="font-size: 10px">
                {{ $t("newAdd.Description3") }}</span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="color: var(--theme); font-size: 10px"><strong>(3)</strong></span><span
                style="font-size: 10px">
                {{ $t("newAdd.Description4") }}</span>
            </p>
            <p>
              <br />
            </p>
            <span style="color: #e53333; font-size: 10px">{{ $t("newAdd.Note") }} </span><span
              style="font-size: 10px">{{
        $t("newAdd.DescriptionNote")
      }}</span><br />
          </template>
        </div>
      </div>
      <div class="make-synopsis-bottom"></div>
    </div>
    <van-overlay :show="overlayShow" @click="closeOverlay" z-index="9999">
      <div class="wrapper" @click.stop>
        <div class="wrapper_title">{{ overlayTitle == 'buy' ? $t('gift.buy') : $t('gift.away') }}</div>
        <div class="block">
          <div class="block_img">
            <div class="imgs" v-if="overlayTitle == 'buy'">
              <img :src="buyItem && buyItem.picture && $img(buyItem.picture[0])" alt="">
              <div class="title">{{ buyItem && buyItem.title }}</div>
              <div class="amount">x{{ amount }}</div>
            </div>
            <div class="imgs" v-else>
              <img :src="sendItem && sendItem.product_picture && $img(sendItem.product_picture[0])" alt="">
              <div class="title">{{ sendItem && sendItem.product_name }}</div>
              <div class="amount">x{{ amount }}</div>
            </div>
          </div>
          <div class="block_cont" style="margin: calc(6rem / 16) 0;">
            <div class="cont_left">{{ $t('gift.overlay.num') }}:</div>
            <div class="cont_value">
              <van-stepper v-if="overlayTitle == 'buy'" v-model="amount" integer theme="round" button-size="22" />
              <van-stepper v-else v-model="amount" :max="sendItem && (sendItem.amount - sendItem.gift_amount)" integer
                theme="round" button-size="22" />
            </div>
          </div>
          <div class="block_cont" v-if="overlayTitle == 'send'">
            <div class="cont_left">{{ $t('gift.overlay.room') }}:</div>
            <div class="cont_value">
              <input v-model="remark" class="input" type="text" :placeholder="$t('gift.overlay.inputPlaceholder')">
            </div>
          </div>
          <div v-if="overlayTitle == 'buy'" class="btn"
            :style="theme == 'light' ? { color: 'var(--btnsizeColor)' } : {  }" @click="handleBuy">
            {{ $t('gift.buy') }}
          </div>
          <div v-else class="btn" :style="theme == 'light' ? { color: 'var(--btnsizeColor)' } : { }"
            @click="handleSend">
            {{ $t('gift.away') }}
          </div>
        </div>
      </div>
    </van-overlay>
    <div class="buy-box" v-if="isBuyBoxShow">
      <div class="cont">
        <div>
          <div class="goodsInfo">
            <div class="close" @click="closeBuy">
              <img src="../../assets/icon/close.png" alt="" />
            </div>
            <p>{{ $t("Cart.zzInfo.buyIng") }}······</p>
          </div>
          <div>
            <van-progress :percentage="percentage" stroke-width="20" />
          </div>
          <span class="space-text">
            {{ $t("newAdd.WaitDispatch") }}
          </span>
        </div>
      </div>
      <div class="zz" @click="handleColesZZ"></div>
    </div>
    <van-overlay v-if="goodsShow" :show="goodsShow" z-index="10">
      <div class="wrappers">
        <div class="goods-block">
          <div class="goods-buy-info">
            <div class="goods-buy-item"></div>
            <p class="goods-buy-item-order-id colorBrown">
              {{ $t("newAdd.OrderNum") }}{{ goodsInfo.order_no }}
            </p>
            <div class="goods-info-content">
              <div class="goods-info-image-box">
                <img class="goods-info-image" v-if="goodsInfo.product_picture"
                  :src="`${domain}${goodsInfo.product_picture}`" />
              </div>
              <div class="goods-info-item">
                <div class="goods-info-item-name">
                  <div class="goods-info-item-name-relative">
                    <p>{{ goodsInfo.product_name }}</p>
                  </div>
                </div>
                <div class="goods-info-item-info">
                  <div class="goods-info-item-info-monye">
                    {{ goodsInfo.price }}
                  </div>
                  <div class="goods-info-item-info-num">
                    X{{ goodsInfo.amount }}
                  </div>
                </div>
              </div>
            </div>
            <div class="goods-buy-item">
              <span class="colorBrown">{{ $t("newAdd.TotalOrderPrice") }}</span>
              <p class="goods-info-money">{{ goodsInfo.total_price }}</p>
            </div>
            <div class="goods-buy-item">
              <span class="colorBrown">{{ $t("newAdd.commission") }}</span>
              <p class="goods-info-commission colorBrown">
                {{ (goodsInfo.total_price * goodsInfo.rate) / 100 }}
              </p>
            </div>
            <div class="goods-buy-item expected-item">
              <span>{{ $t("newAdd.ExpectedReturn") }}</span>
              <p class="goods-info-money">
                {{
        Number(goodsInfo.total_price) +
        (goodsInfo.total_price * goodsInfo.rate) / 100
                }}
              </p>
            </div>
          </div>
          <div class="pop_btn" @click="handleSubmitNow">
            {{ $t("newAdd.SubmitNow") }}
          </div>
          <div class="close-btn" @click="goodsShow = false"></div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  GET_CONFIG_INFO,
  GET_RECHARGE_LIST,
  getUserInfoNew,
  RECHARGE_COMMIT,
  MY_GIFT,
  BUY_GIFT,
  SEND_GIFT
} from "@/api";
import Cookies from "js-cookie";
import { domain, $get } from "@/utils/request";
import { getUserInfo } from "@/utils/tools";
import Tabbar from "@/components/Tabbar";
import verConfig from "@/utils/verConfig";
import {
  NavBar,
  cell,
  icon,
  field,
  Dialog,
  Toast,
  progress,
  Grid,
  GridItem,
  tabs,
  tab,
  Button,
  Image as VanImage,
  Overlay,
  Stepper
} from "vant";
import { mapGetters } from 'vuex';

export default {
  components: {
    [NavBar.name]: NavBar,
    [cell.name]: cell,
    [icon.name]: icon,
    [field.name]: field,
    [Toast.name]: Toast,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [tabs.name]: tabs,
    [tab.name]: tab,
    [Dialog.Component.name]: Dialog.Component,
    [Button.name]: Button,
    Tabbar,
    VanImage,
    [Overlay.name]: Overlay,
    [Stepper.name]: Stepper,
    [progress.name]: progress,
  },
  data() {
    return {
      domain: domain,
      verConfig,
      countList: [
        "100",
        "200",
        "500",
        "1000",
        "5000",
        "10000",
        "30000",
        "50000",
      ],
      account: "",
      number: "",
      active: 0,
      tabPages: [],
      showTutorial: false,
      count: 1,
      serviceURL: "",
      country: Cookies.get("language"),
      goodInfo: {},
      rechargeType: "",
      // userInfo: {},
      useNetWorkList: [],
      useNetWorkActive: 0,
      newForm: {
        money: 0,
        netWork: "",
        hash: "",
      },
      rechargePath: this.verConfig.pathNewRecharge
        ? "third-recharge-n"
        : "new-recharge",
      isTabBarLinkNotShowShopMoney: false,
      activeIndex: 0,
      myactiveIndex: 0,
      mygift: [],
      remark: '',
      overlayShow: false,
      overlayTitle: 'buy',
      amount: 1,
      isBuyBoxShow: false,
      percentage: 0,
      getOrderSuccess: true,
      goodsInfo: {
        date: "2021-08-01 14:11:44",
        orderId: "202108010962489410",
        money: "500",
        commission: "700",
        expected: "6546",
        icon: "",
        name: "Chuột không dây sạc pin siêu mỏng 2.4GHz HXSJ M01Chuột không dây sạc pin siêu mỏng 2.4GHz HXSJ M01",
        num: "100",
      },
      goodsShow: false,
    };
  },
  created() {
    getUserInfoNew();
    this.getRechargeList();
    this.getMygift();
    // this.getLists()
    // this.getService()
    // console.log(Cookies.get('language'));
  },
  mounted() {
    // this.userInfo = getUserInfo();
    this.account = getUserInfo().userphone || getUserInfo().email;
    const data = localStorage.getItem("rechargeGoodDataInfo")
      ? JSON.parse(localStorage.getItem("rechargeGoodDataInfo"))
      : this.$route.query;
    // this.goodInfo = JSON.parse(this.$route.query.data);
    this.goodInfo =
      typeof data.data === "string" ? JSON.parse(data.data) : data.data;
    this.rechargeType = data.type;
    if (this.goodInfo) {
      this.number = this.goodInfo.goods_price;
      this.isTabBarLinkNotShowShopMoney = true;
    }
  },
  methods: {
    updateUserInfo() {
      if (this.$cookie.get("userInfo")) {
        let userInfo = JSON.parse(this.$cookie.get("userInfo"));
        delete userInfo.level;
        this.$cookie.set("userInfo", JSON.stringify(userInfo));
        this.$api.getUserInfo();
      }
    },
    progress60() {
      let auth_timetimer = setInterval(() => {
        this.percentage++;
        if (this.percentage > 30) {
          clearInterval(auth_timetimer);
        }
      }, 20);
      let auth_timetimer2 = setInterval(() => {
        if (this.percentage >= 31 && this.percentage <= 70) {
          this.percentage++;
        }
        if (this.percentage > 60) {
          clearInterval(auth_timetimer2);
        }
      }, 80);
    },
    handleBuys(item) {
      if (this.$cookie.get("token")) {
        this.isBuyBoxShow = true;
        this.percentage = 0;
        this.$api.getTask(
          { level: this.userInfo.level },
          (res) => {
            this.getOrderSuccess = true;
            let auth_timetimer3 = setInterval(() => {
              if (this.percentage >= 61 && this.percentage < 100) {
                this.percentage++;
              }
              if (this.percentage === 100) {
                clearInterval(auth_timetimer3);
                this.goodsInfo = res;
                this.updateUserInfo();
                setTimeout(() => {
                  this.isBuyBoxShow = false;
                  // 打开订单提示框
                  this.goodsShow = true;
                }, 300);
              }
            }, 30);
          },
          (err) => {
            this.getOrderSuccess = false;
            this.interval = setInterval(() => {
              this.$api.getTask(
                { level: this.userInfo.level },
                (r) => {
                  this.getOrderSuccess = true;
                  let auth_timetimer3 = setInterval(() => {
                    if (this.percentage >= 61 && this.percentage < 100) {
                      this.percentage++;
                    }
                    if (this.percentage === 100) {
                      clearInterval(auth_timetimer3);
                      this.goodsInfo = r;
                      this.updateUserInfo();
                      this.orderError = "";
                      setTimeout(() => {
                        this.isBuyBoxShow = false;
                        this.goodsShow = true;
                      }, 300);
                    }
                  }, 30);
                  clearInterval(this.interval);
                },
                (e) => {
                  this.getOrderSuccess = false;
                  this.orderError = e;
                }
              );
            }, 3000);
            let auth_timetimer3 = setInterval(() => {
              if (this.percentage >= 61 && this.percentage < 99) {
                this.percentage++;
              }
              if (this.percentage === 99) {
                clearInterval(auth_timetimer3);
                clearInterval(this.interval);
                setTimeout(() => {
                  if (!this.getOrderSuccess) {
                    if (this.orderError == "") {
                      this.$toast(this.$t("newAdd.NotBeenSentOrder"));
                    } else {
                      this.$toast(this.orderError);
                    }
                  }
                  this.orderError = "";
                  // 按钮允许点击
                  this.isBuyBoxShow = false;
                }, 500);
              }
            }, 100);
          }
        );
        this.progress60();
      } else {
        this.$toast(this.$t("newAdd.NotLogin"));
        setTimeout(() => {
          this.$router.replace({ name: "login" });
        }, 1000);
      }
    },
    handleColesZZ() {
      this.isBuyBoxShow = false;
      this.isBuyFailShow = false;
      this.isBuyFailNotMoneyShow = false;
      this.buyGoodsForm = {};
    },
    handleSubmitNow() {
      this.$router.push({ name: "cart" });
    },
    closeBuy() {
      this.isBuyBoxShow = false;
      this.getOrderSuccess = true;
      clearInterval(this.interval);
    },
    closeOverlay() {
      this.overlayShow = false;
      this.account = 1;
      this.remark = ""
    },
    async buyClick() {
      this.overlayTitle = 'buy'
      this.overlayShow = true;
    },
    sendClick() {
      this.overlayTitle = 'send'
      this.overlayShow = true;
    },
    handleBuy() {
      this.buyGift(this.buyItem.id, this.amount);
    },
    async handleSend() {
      this.sendGift(this.sendItem.id, this.amount)
    },
    async getMygift() {
      try {
        const res = await MY_GIFT();
        this.mygift = res.data.lists
      } catch (error) {
        console.error(error);
      }
    },
    async buyGift(id, amount) {
      Toast.loading();
      try {
        const res = await BUY_GIFT({
          id,
          amount
        });
        this.getMygift();
        this.amount = 1;
        this.$store.dispatch("getUserInfo");
        this.overlayShow = false;

        Toast(res.msg)
      } catch (err) {
        Toast(err.msg)
      }
    },
    async sendGift(id, amount) {
      Toast.loading();
      try {
        const res = await SEND_GIFT({
          id,
          amount,
          remark: this.remark
        });
        this.getMygift();
        this.amount = 1;
        Toast(res.msg);
        this.remark = ''
        this.overlayShow = false;
      } catch (err) {
        Toast(err.msg)
      }
    },
    mylistClick(index) {
      this.myactiveIndex = index
    },
    listClick(index) {
      this.activeIndex = index;
    },
    handleTabItemClick(item, index) {
      if (this.useNetWorkActive === index) return;
      this.useNetWorkActive = index;
      this.newForm.netWork = item.title;
    },
    handleCommit() {
      const { hash, money } = this.newForm;
      if (!hash) return Toast(this.$t("newAdd.commitHint"));
      this.paySubmit(this.useNetWorkList[this.useNetWorkActive]);
    },
    handleUseNetWorkChange(name, title) {
      this.newForm.netWork = title;
    },
    async handleCopy(copyText) {
      // if (!copyText) return Toast('Copy Fail')
      // this.$copyText(copyText).then((e) => {
      //   Toast('Copy successfully')
      // })
      let pasteStr = await navigator.clipboard.readText();
      this.newForm.hash = pasteStr;
    },
    // add
    goback() {
      history.back();
    },
    getRechargeList() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_RECHARGE_LIST()
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 1) {
            Toast.clear();
            this.tabPages = r.data.data;
            this.useNetWorkList = this.tabPages.find(
              (_) => _.type === "usdt"
            )?.lists;
            this.newForm.netWork =
              this.useNetWorkList[this.useNetWorkActive].title;
          }
        })
        .catch((e) => {
          Toast.clear();
        });
    },
    // 将点击项的值绑定给充值金额框
    addClass(value) {
      this.number = value;
    },

    // 获取充值方式的数据
    async getLists() {
      const {
        data: { data, ret },
      } = await request({
        method: "get",
        url: "pay/lists",
      });
      if (ret === 1) {
        this.tabPages = data;
      }
    },

    // async getService () {
    //   const { data: { data, ret } } = await request({
    //     method: 'get',
    //     url: 'other/config',
    //     params: {
    //       act: 'system',
    //       refresh: this.isLoading === true ? 1 : 0
    //     }
    //   })
    //   if (ret === 1) {
    //     this.serviceURL = data.service_url
    //   }
    // },
    // 教程
    changeImg() {
      this.count += 1;
      if (this.count === 6) {
        this.showTutorial = false;
        this.count = 1;
      }
    },

    handlerService() {
      //   Toast({
      //     message: 'Try again later'
      //   })
      window.location.href = this.serviceURL;
    },

    paySubmit(item) {
      const { hash, money } = this.newForm;
      const { id } = item;
      let params = {
        id,
        money,
        hash,
        account: this.account,
        isbefore: 1,
      };
      if (this.isTabBarLinkNotShowShopMoney) {
        params.third_id =
          this.rechargeType !== "third"
            ? Number(this.goodInfo.id) + 100000000
            : this.goodInfo.id;
      }

      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      RECHARGE_COMMIT(params)
        .then((r) => {
          Toast.clear();

          const { ret, msg } = r.data;

          if (!ret) return Toast(msg);

          // return this.$router.push("recharge-list");
          // return this.$router.push("thirdRechargeList");
          Dialog.alert({
            message: msg,
            confirmButtonText: this.$t("Button.confirm"),
            confirmButtonColor: "var(--theme)",
          });
          return;

          if (r.data.ret === 2) {
            Toast.clear();
            window.location.href = r.data.data.url;
          } else if (r.data.ret === 1) {
            if (r.data.data.bank.indexOf("qrcode") != -1) {
              r.data.data.step = item.step;
              r.data.data.id = item.id;
              this.$router.push({
                name: "qrPay",
                params: {
                  payData: r.data.data,
                },
              });
            }
          } else if (r.data.ret === 3) {
            if (r.data.data.bank.indexOf("bank-BANK") != -1) {
              r.data.data.step = item.step;
              r.data.data.id = item.id;
              this.$router.push({
                name: "bankPay",
                params: {
                  payData: r.data.data,
                },
              });
            }
          } else {
            // Toast('System exception, please try again later')
            Toast(r.data.msg);
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
  },
  beforeDestroy() {
    localStorage.removeItem("rechargeGoodDataInfo");
  },
  computed: {
    addCount() {
      return this.count;
    },
    ...mapGetters(["userInfo", "levels", "theme", "giftList"]),
    buyItem() {
      return this.giftList[this.activeIndex]
    },
    sendItem() {
      return this.mygift && this.mygift[this.myactiveIndex]
    },
    // accounts() {
    //   return this.userInfo.userphone || this.userInfo.email;
    // }
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
@Bc: var(--nav-background);
@bg: var(--bg);
@sizeColor: var(--sizeColor);

.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

.wrap {
  min-height: 100vh;
  // background: #f6f8f9;
  background: @Bc;
  padding-bottom: calc(20rem / 16);

  .userinfo_bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    // background-image: url("../../assets/recharge/bg.png");
    background-color: var(--nav-background);
    background-size: 100% 100%;
    @tradeinfo_height: calc(90rem / 16);
    padding: calc(24rem / 16) calc(18rem / 16) calc(55rem / 16);
    margin-bottom: calc(15rem / 16 + @tradeinfo_height / 2);

    .nav_title {
      // color: #fff;
      color: @Color;
      // font-family: PingFangSC-Medium;
      font-size: calc(18rem / 16);
      margin-bottom: calc(18rem / 16);
    }

    .avatar {
      background-size: 100% 100%;
    }

    .level_bar {
      display: flex;
      justify-content: space-around;
      align-items: center;
      // color: #fff;
      color: @Color;
      margin-top: calc(14rem / 16);
      margin-bottom: calc(20rem / 16);
      // font-family: PingFangSC-Medium;
      font-size: calc(15rem / 16);

      .hr {
        width: 1px;
        height: calc(22rem / 16);
        // background: #fff;
        background: @Color;
        margin: 0 calc(16rem / 16);
      }
    }

    .record_bar {
      position: absolute;
      top: calc(10rem / 16);
      right: calc(10rem / 16);
      text-align: center;
      color: #fff;
      // background: #4f02dc;
      background: var(--newBtn);
      border-radius: 50%;

      &:active {
        opacity: 0.8;
      }

      .iconfont {
        font-size: calc(40rem / 16);
      }

      .text {
        font-size: calc(15rem / 16);
      }
    }

    .tradeinfo_bar {
      position: absolute;
      bottom: calc(@tradeinfo_height / 2 * -1);
      margin: 0 calc(13rem / 16);
      // background: #fff;
      background: @bg;
      border-radius: calc(10rem / 16);
      padding: 0 calc(25rem / 16);
      height: @tradeinfo_height;
      width: calc(100% - (13rem / 16) * 2 - (25rem / 16) * 2);

      >div {
        height: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: calc(18rem / 16);

        // font-family: PingFangSC-Medium;
        .title {
          // color: #6e87a0;
          color: @Color;
        }

        .value {
          // color: #000;
          color: @sizeColor;
        }
      }
    }
  }


  .shop {
    padding: 0 calc(13rem / 16);

    .shop_title {
      margin: calc(16rem / 16) 0;
      background-color: var(--bg);
      text-align: center;
      color: var(--theme);
      border-radius: calc(10rem / 16);
      padding: calc(8rem / 16) 0;
      font-size: calc(16rem / 16);
    }

    .gifts {
      border-radius: calc(10rem / 16);
      background-color: var(--bg);

      &_cont {
        padding-top: calc(16rem / 16);

        .lists {
          display: flex;
          flex-wrap: wrap;
          padding-right: calc(12rem / 16);

          .nodata {
            flex: 1;
            color: var(--theme);
            font-size: calc(16rem / 16);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: calc(20rem / 16)
          }
        }

        .cont_list {
          width: calc(25% - (26rem / 16));
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: calc(12rem / 16);
          margin-bottom: calc(16rem / 16);
          padding: calc(6rem / 16);
          border: 1px solid;
          border-color: transparent;
          border-radius: calc(10rem / 16);

          .list_img {
            img {
              width: calc(60rem / 16);
              height: calc(60rem / 16);
            }
          }

          .list_title {
            color: var(--sizeColor);
            font-size: calc(14rem / 16);
            margin-top: calc(6rem / 16);
            margin-bottom: (4rem / 16);
          }

          .list_money {
            color: #BBBBBB;
            font-size: calc(10rem / 16);
          }
        }
      }

      &_btns {
        display: flex;
        padding: 0 calc(16rem / 16);
        margin-top: calc(4rem / 16);
        padding-bottom: calc(16rem / 16);

        .btn {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--newBtn);
          color: var(--btnsizeColor);
          padding: calc(8rem / 16) 0;
          border-radius: calc(10rem / 16);
        }

        .right_btn {
          margin-left: calc(16rem / 16);
        }
      }

    }
  }

  .game-btn {
    width: 90%;
    text-align: center;
    background: var(--newBtn);
    margin: 0 auto;
    color: var(--sizeColor);
    padding: 2.667vw 0;
    border-radius: 999px;
    margin-top: 5px;
  }

  .form_bar {
    margin: 0 calc(13rem / 16);
    padding: calc(13rem / 16);
    border-radius: calc(10rem / 16);
    // background: #0271dc;
    background: @bg;

    .submission_bar {
      @height: calc(60rem / 16);
      height: @height;
      // background: #fff;
      background: var(--btnColor);
      border-radius: calc(5rem / 16);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 calc(10rem / 16);

      .title {
        font-size: calc(12rem / 16);
        // color: #6e87a0;
        // font-family: PingFangSC-Medium;
        white-space: nowrap;
        color: @Color;
      }

      >.input {
        margin-left: calc(10rem / 16);
        flex-grow: 1;
        overflow: hidden;
        // border-bottom: 1px solid #0271dc;
        border-bottom: 1px solid @Color;
        display: flex;
        // align-items: center;
        align-items: flex-end;

        >input {
          border: none;
          height: calc(@height * 0.5);
          // color: #0271dc;
          color: @Color;
          background: transparent;
        }
      }
    }

    .type_bar {
      padding: calc(15rem / 16) 0;
      display: flex;
      justify-content: space-between;

      >div {
        line-height: calc(35rem / 16);
        text-align: center;
        // color: #fff;
        color: @sizeColor;
        // font-family: PingFangSC-Medium;
        font-size: calc(12rem / 16);
        font-weight: normal;
        white-space: nowrap;
        padding: 0 calc(12rem / 16);
        border-radius: calc(5rem / 16);
      }

      .active {
        // background: #fff;
        background: var(--btnColor);
        color: @Color;
        // color: #0271dc;
      }
    }

    .textarea_wrap {
      position: relative;
      padding: calc(13rem / 16);
      // background: #fff;
      background: @bg;
      border-radius: calc(5rem / 16);
      border: 2px solid @Bc;

      .hash_bar {
        // background: #fff;
        height: calc(125rem / 16);
        border: none;
        width: 100%;
        background-color: @bg;
        color: @Color;
      }

      .btn_past {
        border-radius: 50%;
        @size: calc(50rem / 16);
        width: @size;
        line-height: @size;
        text-align: center;
        // background-color: #5a00ff;
        background-color: var(--btnColor);
        // color: #fff;
        color: var(--btnsizeColor);
        // color: @Bc;
        position: absolute;
        bottom: calc(13rem / 16);
        right: calc(13rem / 16);

        &:active {
          opacity: 0.8;
        }
      }
    }
  }

  .submit_bar {
    margin: calc(33rem / 16) calc(13rem / 16);
    text-align: center;
    // background: #0271dc;
    background: var(--btnColor);
    line-height: calc(45rem / 16);
    // color: #fff;
    color: var(--btnsizeColor);
    border-radius: calc(5rem / 16);
  }

  /deep/ .van-overlay {
    right: 0;
    display: flex;
    align-items: flex-end;
  }

  .wrapper {
    width: 100%;
    padding: calc(16rem / 16);

    &_title {
      background-color: var(--bg);
      color: var(--theme);
      text-align: center;
      padding: calc(6rem / 16) 0;
      border-radius: calc(10rem / 16);
    }
  }

  .block {
    background-color: var(--bg);
    margin: calc(16rem / 16) 0;
    margin-bottom: 0;
    border-radius: calc(10rem / 16);
    padding: calc(16rem / 16);

    &_img {
      display: flex;
      justify-content: center;

      .imgs {
        display: inline-block;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: calc(16rem / 16) calc(34rem / 16);
        border: 1px solid #363637;
        border-radius: calc(10rem / 16);

        img {
          width: calc(60rem / 16);
          height: calc(60rem / 16);
          display: block;
        }

        .title {
          color: var(--sizeColor);
          font-size: calc(16rem / 16);
          text-align: center;
          margin-top: calc(12rem / 16);
          margin-bottom: calc(8rem / 16);
        }

        .amount {
          text-align: center;
          color: #BBBBBB;
          font-size: calc(12rem / 16);
        }
      }
    }

    &_cont {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cont_left {
        color: var(--theme);
        font-size: calc(12rem / 16);
      }

      .cont_value {
        /deep/ .van-stepper__input {
          color: var(--theme);
        }

        .input {
          width: calc(70rem / 16);
          color: var(--theme);
          font-size: calc(12rem / 16);
          background-color: transparent;
          border-color: transparent;
          border-bottom: 1px solid rgb(118, 118, 118), ;
        }
      }
    }

    .btn {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--newBtn);
      color: var(--btnsizeColor);
      padding: calc(8rem / 16) 0;
      border-radius: calc(10rem / 16);
      margin-top: calc(16rem / 16);
    }
  }
}

.make-synopsis {
  width: 100vw;
  // background-color: #fff;
  background-color: @bg;
  margin-top: 20px;
  font-size: 3.733vw;

  .make-synopsis-title {
    text-align: center;
    color: @Color;
    padding: 10px 0;
  }

  .make-synopsis-content {
    padding: 0 10vw 30px 10vw;
    color: @sizeColor;
  }
}

.buy-box {
  width: 100%;
  height: 100%;
  // position: relative;
  position: fixed;
  // top: -100%;
  top: 0;

  >.cont {
    width: calc(350rem / 16);
    height: calc(460rem / 16);
    // background: url('../../assets/Img/goods/img/DDSC_0001_xdz.png') no-repeat;
    position: absolute;
    z-index: 44;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    >div {
      width: calc(350rem / 18);
      height: calc(460rem / 18);
      margin: 0 auto;
      background: url("../../assets/Img/goods/img/DDSC_0001_xdz.png") no-repeat;
      background-size: 100% 100%;

      >.goodsInfo {
        padding-top: calc(250rem / 16);
        display: flex;
        align-items: center;
        // justify-content: space-between;
        margin-bottom: calc(5rem / 16);
        position: relative;

        >.close {
          position: absolute;
          right: calc(10rem / 16);
          top: calc(120rem / 16);
        }

        >div {
          display: flex;
          align-items: center;
          font-size: calc(13rem / 16);
          color: #9b8383;

          >img {
            width: calc(30rem / 16);
            height: calc(30rem / 16);
            border-radius: 6px;
          }

          >div {
            display: flex;
            flex-direction: column;
            margin-left: calc(15rem / 16);
            width: calc(70rem / 16);
          }
        }

        >p {
          font-size: calc(18rem / 16);
          text-align: center;
          color: #9b8383;
          // margin-bottom: calc(2rem / 16);
          margin: 0 0 calc(2rem / 16) calc(15rem / 16);
        }
      }

      >div {
        width: 98%;
        margin-left: calc(6rem / 16);
        // margin: auto;
      }

      >span {
        font-size: calc(18rem / 16);
        display: block;
        text-align: center;
        color: #9b8383;
        // margin: calc(15rem / 16) calc(22rem / 16) calc(2rem / 16) 0;
        margin: calc(15rem / 16) 0 calc(2rem / 16) 0;
      }

      >button {
        width: calc(100% - (60rem / 16));
        height: calc(60rem / 16);
        margin: auto;
        display: block;
        // margin-right: calc(40rem / 16);
        border-radius: 30px;
        border: 0;
        color: #fff;
        background: #c4cec9;
        font-size: calc(22rem / 16);
        font-weight: 200;

        &.on {
          background: #48db8d;
        }
      }
    }
  }
}

.wrappers {
  .flex();
  width: 100%;
  height: 100vh;

  .goods-info-image {
    width: 20vw;
    // float: left;
  }

  .goods-buy-item,
  .goods-info-item-info {
    line-height: 1.5em;
    .flex(space-between);
  }

  .goods-info-item {
    font-size: 14px;

    .goods-info-item-info {
      padding: 4vw 0;
    }
  }

  .goods-block {
    .colorBrown {
      color: #a8a8a8;
    }

    width: 70vw;
    // height: 80vh;
    background-color: #ceb198;
    border-radius: 5vw;
    padding: 5vw 2vw 6vw 2vw;

    .goods-buy-info {
      .goods-buy-item-order-id {
        padding: 4vw 0;
        font-style: 13px;
      }

      .goods-info-content {
        display: flex;
      }

      font-size: 15px;
      padding: 3vw;
      border-radius: 2vw;
      background-color: #fff;

      .goods-info-money {
        color: #ff9307;
      }

      .goods-info-image {
        width: calc(80rem / 16);
        height: calc(88rem / 16);
      }
    }
  }

  .pop_btn {
    background-color: #ff3c00;
    width: 80%;
    margin: 0 auto;
    margin-top: 5vw;
    text-align: center;
    padding: 3vw 0;
    border-radius: 2vw;
    color: #fff;
  }
}
</style>